import request from '@/utils/request'
// 商户商品列表
export function getGoodsList(data) {
  return request('post', '/apm/platform/goods/page', data)
}

// 新增商户商品
export function getGoodsAdd(data) {
  return request('post', '/apm/platform/goods/create', data)
}

// 查询商户商品详情
export function getGoodsInfo(data) {
  return request('post', '/apm/platform/goods/findById', data)
}

// 编辑商户商品
export function getGoodsEdit(data) {
  return request('post', '/apm/platform/goods/updateById', data)
}

// 编辑商品进销损
export function getInventoryEdit(data) {
  return request('post', '/apm/platform/goods/inventory/update', data)
}
// 分类列表
export function getGoodsTypeList(data) {
  return request("get", "/apm/platform/goods/type/list", data);
}

// 新增商品分类
export function getGoodsTypeAdd(data) {
  return request("post", "/apm/platform/goods/type/create", data);
}

// 编辑商品分类
export function getGoodsTypeEdit(data) {
  return request("post", "/apm/platform/goods/type/update", data);
}

// 删除商品分类
export function getGoodsTypeDel(data) {
  return request("post", "/apm/platform/goods/type/del", data);
}
// 分类排序
export function sortGoodsType(data) {
  return request("post", "/apm/platform/goods/type/sort", data);
}
// 添加积分兑换商品
export function addExGoods(data) {
  return request("post", "/apm/points/goods/create", data);
}
// 获取积分兑换商品列表
export function getExGoodsList(data) {
  return request("post", "/apm/points/goods/page", data);
}
// 编辑积分兑换商品
export function editExGoods(data) {
  return request("post", "/apm/points/goods/update", data);
}
// 查看积分兑换商品
export function getExGoodsInfo(data) {
  return request("post", "/apm/points/goods/findById", data);
}
// 删除积分兑换商品
export function delExGoods(data) {
  return request("post", "/apm/points/goods/del", data);
}
// 排序积分兑换商品列表
export function sortExGoodsList(data) {
  return request("post", "/apm/points/goods/sort", data);
}
// 上下架积分兑换商品
export function editExGoodsStatus(data) {
  return request("post", "/apm/points/goods/updateGoodsStatus", data);
}
// 补货积分兑换商品
export function replenishExGoods(data) {
  return request("post", "/apm/points/goods/replenish", data);
}