<!--积分兑换商品-->
<template>
  <div class="mall-goods-shop">
    <!-- 查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input v-model="tableParams.kw" placeholder="请输入商品名称搜索" @input="kwChange" clearable class="mr15" />
        <el-select v-model="tableParams.goodsStatus" placeholder="请选择商品状态" @change="reload" clearable>
          <el-option v-for="item in ObjToOpt(goodsStatusObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">添加兑换商品</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table :data="tableData" border :header-cell-style="{
          background: '#FAFAFA',
          color: '#000000',
        }">
      <el-table-column prop="sortNum" label="序号" width="50">
      </el-table-column>
      <el-table-column label="兑换商品" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsName || "-" }}</span>
        </template>
      </el-table-column>

      <el-table-column label="所属商品类别" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品原价" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.goodsPrice || "0" }}</span>

        </template>
      </el-table-column>
      <el-table-column label="兑换所需积分" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.requiredPoints || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="兑换所需现金" align="center">
        <template slot-scope="scope">
          <span>￥{{ scope.row.requiredAmount || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="库存" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsStock || "0" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品状态" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsStatus == 1 ? "售卖中" : "下架" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click.stop="onEdit(scope.row)">编辑</el-button>
          <el-button type="text" size="medium" @click.stop="onDel(scope.row)"
            v-if="scope.row.goodsStatus == 0">删除</el-button>
          <el-button type="text" size="medium" @click.stop="onUp(scope.row)"
            v-if="!scope.row.goodsStatus">上架</el-button>
          <el-button type="text" size="medium" @click.stop="onDown(scope.row)" v-else>下架</el-button>
          <el-button type="text" size="medium" @click.stop="onReplenish(scope.row)">补货</el-button>
          <el-button type="text" size="medium" @click.stop="sort(scope.row)">排序</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
    <!-- 编辑弹框 -->
    <el-dialog :title="editTitle" :visible.sync="editShow" :close-on-click-modal="false" append-to-body width="40%"
      @close="close">
      <!-- 表单提交 -->
      <el-form :model="goodsInfo" :rules="rules" ref="form-ref" label-width="150px">
        <section class="form-main">
          <el-form-item label="选择商品" prop="goodsId">
            <el-input v-if="goodsInfo.goodsName" v-model="goodsInfo.goodsName" :disabled="true">
            </el-input>
            <el-select  v-else v-model="goodsInfo.goodsId" filterable remote reserve-keyword
              :disabled="this.editTitle == '编辑兑换商品'" placeholder="搜索选择平台商品" :remote-method="remoteMethod"
              :loading="loading" :popper-class="'custom-select-dropdown'
          ">
              <div style="min-height: 180px">
                <el-option v-for="item in goodsList" :key="item.goodsId" :label="item.goodsName" :value="item.goodsId">
                </el-option>
              </div>
              <div class="custom-pagination">
                <el-pagination background layout="prev, pager, next" :page-size="goodsParams.pageSize"
                  :total="goodsOptionTotal" @current-change="handlePageChange" :current-page.sync="currentPage">
                </el-pagination>
              </div>
            </el-select>
          </el-form-item>
          <el-form-item label="所属商品类别" prop="goodsTypeId">
            <el-select v-model="goodsInfo.goodsTypeId" placeholder="选择商品类别" clearable>
              <el-option v-for="item in typeList" :key="item.goodsTypeId" :label="item.goodsTypeName"
                :value="item.goodsTypeId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品原价" prop="goodsPrice">
            <el-input type="number" v-model="goodsInfo.goodsPrice" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请输入原价"
              @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          ">
              <template slot="prepend">￥</template></el-input>
          </el-form-item>
          <el-form-item label="兑换所需积分" prop="requiredPoints">
            <el-input type="number" v-model="goodsInfo.requiredPoints" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请输入积分数"
              @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          ">
            </el-input>
          </el-form-item>
          <el-form-item label="兑换所需现金" prop="requiredAmount">
            <el-input type="number" v-model="goodsInfo.requiredAmount" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请填写兑换所需现金"
              @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          ">
              <template slot="prepend">￥</template></el-input>
          </el-form-item>
          <el-form-item label="兑换库存" prop="goodsStock">
            <el-input type="number" v-model="goodsInfo.goodsStock" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请输入兑换库存数"
              @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          ">
            </el-input>
          </el-form-item>


          <el-form-item label="商品领取门店" prop="shopIdList">
            <el-select v-model="goodsInfo.shopIdList" multiple collapse-tags placeholder="搜索选择门店" clearable filterable remote reserve-keyword :remote-method="shopRemoteMethod">
              <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
              </el-option>
            </el-select>
          </el-form-item>
         
          <el-form-item label="每人限兑数" prop="quotaNum">
            <el-input type="number" v-model="goodsInfo.quotaNum" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="不填表示无限制" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          ">
              <template slot="append">件</template></el-input>
          </el-form-item>
          <el-form-item label="排序值" prop="sortNum">
            <el-input type="number" v-model="goodsInfo.sortNum" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="输入排序值" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          </el-form-item>
          <el-form-item label="商品备注" prop="goodsRemark">
    <el-input type="textarea" maxlength="200" autosize show-word-limit v-model="goodsInfo.goodsRemark" placeholder="输入200字以内备注"></el-input>
  </el-form-item>
          <el-form-item label="状态">
            <el-radio-group v-model="goodsInfo.goodsStatus">
              <el-radio :label="0">下架</el-radio>
              <el-radio :label="1">售卖</el-radio>
            </el-radio-group>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onSubmit('form-ref')">确定</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!-- 补货弹框 -->
    <el-dialog title="商品补货" :visible.sync="replenishShow" :close-on-click-modal="false" append-to-body width="30%">
      <el-form :model="replenishParams" :rules="rules" ref="form-replenish" label-width="100px">
        <section class="form-main">
          <el-form-item label="补货数量" prop="goodsStock">
            <el-input type="number" v-model="replenishParams.goodsStock" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="请填写补货数量"
              @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          </el-form-item>
        </section>
        <section class="form-footer">
          <el-button type="primary" @click="onReplenishSubmit('form-replenish')">保存</el-button>
        </section>
      </el-form>
    </el-dialog>
    <!--排序-->
    <el-dialog title="排序" :visible.sync="sortShow" :close-on-click-modal="false" append-to-body width="30%">
      <section class="form-main">
        <el-form :model="goodsInfo" :rules="rules" ref="form-replenish" label-width="100px">
          <el-form-item label="排序值" prop="sortNum">
            <el-input type="number" v-model="goodsInfo.sortNum" oninput="if(value.length>10)value=value.slice(0,10)" placeholder="排序值" @keydown.native="(e) =>
        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(
          e.key
        )
          ? false
          : e.returnValue)
          "></el-input>
          </el-form-item>
        </el-form>

      </section>
      <section class="form-footer">
        <el-button type="primary" @click="updateSort()">确定</el-button>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import {
  getGoodsList,
  addExGoods,
  getGoodsTypeList,
  getExGoodsList,
  editExGoods,
  delExGoods,
  getExGoodsInfo,
  sortExGoodsList,
  editExGoodsStatus,
  replenishExGoods
} from "@/api/point/goods";
import {
  getShopList,
} from "@/api/commercial/shop";

import { ObjToOpt } from "@/utils/utils";
import { goodsStatusObj } from "@/db/objs";
import { rules } from "@/db/rules";
export default {
  data() {
    return {
      ObjToOpt,
      goodsStatusObj,
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        pageSize: 10,
        kw: "", //查询关键字
        goodsStatus: "", //商品分类
      },
      // 商品分类列表
      typeList: [],
      goodsList: [],
      shopList: [],
      // 编辑弹框
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      // from表单
      rules,
      goodsParams: {
        page: 1,
        pageSize: 5,
        kw: "",//查询关键字
      },
      shopParams: {
        page: 1,
        size: 0,
        shopStatus: 1,
        keyWord: "",//查询关键字
      },
      goodsInfo: {
        goodsStatus: 0,
        sortNum: 1,
        requiredAmount:0
      },
      // 补货弹框
      replenishShow: false, //弹框开关
      replenishParams: {
        goodsStock: "",
        goodsConfigId: "",
      },
      sortShow: false,
      goodsConfigId: "",
      goodsName: "",
      loading: false,
      goodsOptionTotal: 0
    };
  },
  created() {

  },
  mounted() {
    this._getExGoodsList();
    this.getGoodsTypeList();
    this._getShopList()
  },
  methods: {
    reset() {
      this.goodsInfo = {
        goodsStatus: 0,
        sortNum: 1,
        requiredAmount:0
      }
    },
    close() {
      this.reset()
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },
    // 选择已经启用的店铺作为商品可领取店铺
    _getShopList() {
    
      getShopList(this.shopParams).then(res => {
        if (res.isSuccess == 'yes') {
          this.shopList = res.data.list
        }
      })
    },


    // 【请求】点单商品列表
    _getExGoodsList() {

      getExGoodsList(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 获取商品选择配置项
    getPlatformGoodsList() {
      getGoodsList(this.goodsParams).then((res) => {
        if (res.isSuccess == "yes") {
          if (res.data) {
            this.goodsList = res.data.list;
            this.goodsOptionTotal = res.data.total
            this.loading = false
          }
          this.$forceUpdate();
        }
      });
    },

    // 【请求】点单商品分类列表
    getGoodsTypeList() {
      getGoodsTypeList().then((res) => {
        if (res.isSuccess == "yes") {
          this.typeList = res.data;
        }
      });
    },

    // 【请求】新增积分兑换商品
    addExchangeGoods() {
      addExGoods(this.goodsInfo).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.$refs["form-ref"].resetFields();
          this.reload(); // 【请求】表格数据
        }
      });
    },

    // 【请求】编辑点单商品
    editExGoods() {
      editExGoods(this.goodsInfo).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.editShow = false;
          this.$refs["form-ref"].resetFields();
          this.reload(); // 【请求】表格数据
        }
      });
    },



    // 【请求】上下架点单商品
    _editExGoodsStatus() {
      editExGoodsStatus(this.goodsInfo).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.$refs["form-ref"].resetFields();
          this.reset()
          this.reload();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this._getExGoodsList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this._getExGoodsList();
    },

    // 【监听】表格条数点击
    onSizeChange(pageSize) {
      this.tableParams.pageSize = pageSize;
      this.reload();
    },

    // 【监听】新增
    onAdd() {
      this.editShow = true;
      this.editTitle = "添加兑换商品";
      this.getPlatformGoodsList();


    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑兑换商品";
      this.goodsInfo = JSON.parse(JSON.stringify(row));
      let arr = JSON.parse(JSON.stringify(row)).shopIds.split(',')
      this.goodsInfo.shopIdList = arr.map(ite => Number(ite))
      console.log(this.goodsInfo.shopIdList)
      this.getPlatformGoodsList();

    },
    // 【监听】删除
    onDel(row) {
      this.$confirm("是否确定要删除该商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delExGoods({ goodsConfigId: row.goodsConfigId }).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.reload(); // 【请求】表格数据
          }
        });
      });
    },

    // 【监听】上架
    onUp(row) {
    
      this.$confirm("你确定要上架该商品吗?", "商品上架", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.goodsInfo = JSON.parse(JSON.stringify(row));
        this.goodsInfo.goodsStatus = 1;
        this._editExGoodsStatus();
      });
    },

    // 【监听】上架
    onDown(row) {
     
      this.$confirm("你确定要下架该商品吗?", "商品下架", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        this.goodsInfo = JSON.parse(JSON.stringify(row));
        this.goodsInfo.goodsStatus = 0;
        this._editExGoodsStatus();
      });
    },


    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.goodsInfo.goodsConfigId) {
            this.editExGoods();
          } else {
            this.addExchangeGoods();
          }

        } else {
          return false;
        }
      });
    },
    // 【监听】补货
    onReplenish(row) {
      this.replenishShow = true;
      this.replenishParams.goodsConfigId = JSON.parse(JSON.stringify(row)).goodsConfigId;
      this.replenishParams.goodsStock = "";
      this.$nextTick(() => {
        this.$refs["form-replenish"].clearValidate();
      });
    },

    // 【请求】补货积分兑换商品
    GoodsReplenish() {
      replenishExGoods(this.replenishParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.replenishShow = false;
          this.reload(); // 【请求】表格数据
        }
      });
    },
    // 【监听】补货表单提交
    onReplenishSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.GoodsReplenish();
        } else {
          return false;
        }
      });
    },
    // 排序
    sort(row) {
      this.sortShow = true
      this.goodsInfo.goodsConfigId = row.goodsConfigId
      this.goodsInfo.sortNum = row.sortNum
    },
    // 更新排序
    updateSort() {
      sortExGoodsList(this.goodsInfo).then((res) => {
        if (res.isSuccess == "yes") {
          this.sortShow = false
          this.$message({
            message: res.message,
            type: "success",
          });
          this.reload();
        }
      });
    },
    // 卡券搜索
    remoteMethod(query) {
      this.goodsParams.kw = query;
      if (query !== "") {
        this.loading = true;
        this.getPlatformGoodsList();
      } else {
        this.goodsList = [];
      }
    },
    // 门店搜索
    shopRemoteMethod(query) {
      this.shopParams.keyWord = query;
      if (query !== "") {
        this.loading = true;
        this._getShopList();
      } else {
        this.shopList = [];
      }
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.goodsParams.page = page;
      this.getPlatformGoodsList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mall-goods-shop {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.export {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.export-success {
  .el-icon-check {
    font-size: 0.48rem;
  }
}
</style>